.container {
  height: 90vh;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 5.5rem;
}

.text-info {
  padding: 0 6rem;
  font-weight: 500;
}

.fixed-width {
    max-width: 530px;
}
