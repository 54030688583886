.notes-container {
  gap: 10px;
  margin: 0 10%;
}

.searchInput {
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 11px;
  /* align-items: center; */
  color: #9a9a9a;
}

.qtyInput {
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 11px !important;
  /* align-items: center; */
  color: #9a9a9a;
}

.searchInput:focus,
.qtyInput:focus {
  outline: none;
  border: 1px solid #ccc;
}

.modal-header {
    padding: 10px 30px;
}

.formContainer {
  padding: 0 20px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.formContainer input, .formContainer select {
    font-size: 13px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
