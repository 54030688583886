.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/** REACT DATE PICKER **/
.react-datepicker__view-calendar-icon input {
  padding: 0 24px !important;
  height: 24px;
  font-size: 11px;
  /* border-color: #ccc; */
  color: #bcbcbc;
  border-radius: 4px;
  /* box-shadow: none; */
  border: 1px solid #ccc;
  width: 10.5rem;
  /* border-width: 1px; */
}
.react-datepicker__view-calendar-icon input:focus {
  outline: none;
  border: 1px solid #ccc;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  transform: translate(28%, 35%);
  padding: unset !important;
}

.form-check-input:checked {
  background-color: #ebf8eb !important;
  border-color: #c6ebc8 !important;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%2334b53a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e) !important;
}

::-webkit-scrollbar {
  background-color: rgb(222, 222, 222);
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(111, 111, 111, 0.77);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(160, 160, 160, 0.77);
}

.App-link {
  color: #61dafb;
}

.active i {
  background: #4339f2;
  color: white;
  padding: 6px 6px 8px;
  border-radius: 4px;
}

.fixed-column {
  display: flex;
  align-items: center;
  position: absolute;
  min-width: 100px;
  width: min-content;
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}

.gap-5px {
  gap: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*** Heights ***/
.height-3r th,
.height-3r td {
  height: 3rem;
}

.height-4r {
  height: 4rem;
}

/*** FONT SIZES ***/
.fs-11 {
  font-size: 11px !important;
}

.fs-13 {
  font-size: 11px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 20px !important;
}

/*** REACT MODAL ***/

.width-60 > .modal-dialog {
  max-width: 60% !important;
  font-size: 13px;
  font-weight: 500;
}

/*** INPUT FIELD ***/
.edit-column {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.edit-column:focus {
  outline: none;
}

/*** SELECT STATUS ***/
.selected-status {
  background: blue;
  border-radius: 16px;
}

.selected-status span {
  color: white !important;
}

/*** table header backgroun ***/
.custom-table > thead > tr {
  --bs-table-bg: #f8f8f8 !important;
}

td:not(:last-child) {
  border-right: 1px solid gainsboro !important;
}

th:not(:last-child) {
  border-right: 1px solid gainsboro !important;
}

/*** Toggle Styles ***/
.react-toggle-track {
  width: 42px !important;
  height: 16px !important;
  border-radius: 25px !important;
}

.react-toggle-track-check {
  left: 4px !important;
}

.react-toggle-track-x {
  right: 5px !important;
}

.react-toggle-thumb {
  width: 14px !important;
  height: 14px !important;
}

.react-toggle-label {
  color: #898787 !important;
  font-weight: 500;
}

/*** SHADOW OF CARD ***/
.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
  width: --webkit-fill-available;
}

/*** ALIGN ITEMS VERTICALLY AND HORIZONTALLY CENTER ***/
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/*** BUTTTON STYLES ***/
.statusContainer {
  display: flex;
  gap: 10px;
}

.price-button {
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 2px 12px;
  bottom: 0;
  margin-left: 6px;
  background-color: #e2e0ff;
  color: #4339f2;
}

/*** STICKY COLUMNS ***/
.enquiry-status {
  position: sticky;
  left: 0;
  z-index: 1;
  top: 0;
}

/*** TABLE HEIGHT ***/
.table-height-400 {
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
}

/** MEDIA QUERIES **/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1496px !important;
  }
}

@media (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1800px !important;
  }
}
