/* src/components/ProductDetails.module.css */
.tableContainer {
    display: flex;
    padding: 20px 0;
    font-size: 12px;
  }
  
  .customTable {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .customTable th,
  .customTable td {
    text-align: left;
    padding: 10px;
    border: 1px solid #e0e0e0;
  }
  
  .customTable th {
    background-color: #f8f8f8;
    color: #888;
    font-weight: normal;
  }
  
  .statusContainer {
    display: flex;
    gap: 10px;
  }

  .status {
    padding: 3px 10px;
    border-radius: 32px;
    font-size: 11px;
    display: flex;
    align-items: center;
  }
  
  .statusPending {
    background-color: #fff7de;
    color: #ff9800;
  }
  
  .statusPurchased {
    background: #e7e6ff;
    color: #ad00ff;
  }

  .statusRejected {
    background-color: #ffe2e2;
    color: #af4c4c;
  }
  
  .notesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .icon {
    cursor: pointer;
  }
  