.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12rem 6rem;
}

.login-text {
  font-weight: 600 !important;
  font-size: 2.3rem !important;
}

.custom-card {
  border: 2px solid rgba(0, 0, 0, 0.1) !important; /* Custom border color and width */
  border-radius: 12px !important; /* Custom border radius */
  background-color: #fff; /* Ensure the card background is visible */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
}

.custom-card-body {
  border-radius: inherit !important; /* Ensures the body inherits the card's border radius */
}
