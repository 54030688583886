.productCatalogue {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
  font-size: 13px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.select {
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* width: 82px; */
  color: #9a9a9a;
  font-size: 11px;
  height: 24px;
  background-color: #fff;
}

.searchInput {
  padding-top: 2px;
  /* align-items: center; */
  /* display: flex; */
  border: none;
}

.searchContainer {
  display: flex;
  background: white;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 11px;
  gap: 6px;
  /* align-items: center; */
  color: #9a9a9a;
}

.searchIcon {
  position: absolute;
  right: 10px;
}

.col-value {
  display: flex;
  align-items: center;
}

/*** VIEW OFFERS BUTTON ***/
.statusContainer {
  display: flex;
  gap: 10px;
}
.status {
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.view-offers {
  background-color: #e2e0ff;
  color: #4339f2;
}
