.statusContainer {
    display: flex;
    gap: 10px;
  }

  .table {
    font-size: 12px;
  }

  .status {
    padding: 3px 10px;
    border-radius: 4px;
    font-size: 11px;
    display: flex;
    align-items: center;
  }
  
  .view-offers{
    background-color: #e2e0ff;
    color: #4339f2;
  }