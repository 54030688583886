.btn-microsoft {
  border-radius: 8px;
}

.icon-microsoft {
  padding: 6px;
  background: #221b98;
  border-radius: 5px;
  margin: 8px 12px;
}
