.searchContainer {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    padding: 2px 8px;
    width: 160px; /* Adjust the width as needed */
}

.searchInput {
    border: none;
    outline: none;
    flex: 1 1 auto; /* Adjust flex property */
    font-size: 11px; /* Adjust font size here */
    width: 100%;
}

.searchIcon {
    color: #aaa;
    flex-shrink: 0; /* Prevent icon from shrinking */
}