/* Navbar.module.css */
.navbar {
    width: 60px;
    background-color: #f8f9fa;
    padding: 20px 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
    margin: 20px 0;
    transition: transform 0.2s;
}

.icon:hover {
    transform: scale(1.2);
}

/* .active .icon {
    filter: brightness(0) saturate(100%) invert(26%) sepia(94%) saturate(1565%) hue-rotate(210deg) brightness(97%) contrast(106%);
} */

.bottom-0 {
    position: absolute;
    bottom: 2rem;
}