.ntoes-container {
  gap: 10px;
  margin: 0 10%;
}

.searchInput {
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 14px;
  /* align-items: center; */
  color: #9a9a9a;
}

.qtyInput {
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 14px !important;
  /* align-items: center; */
  color: #9a9a9a;
  height: 3rem;
}

.searchInput:focus,
.qtyInput:focus {
  outline: none;
  border: 1px solid #ccc;
}

.btn-continue {
  background-color: #4339f2 !important;
}

.btn-cancel {
  color: black !important;
  font-weight: 500 !important;
}

.btn-continue, .btn-cancel {
  padding: 0.5rem 2rem !important;
}
