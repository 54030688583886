/* NotificationComponent.module.css */
@keyframes popIn {
  0% {
    transform: translateY(-20%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.notificationCard {
  max-width: 400px;
  margin: 0 auto;
  z-index: 1;
  right: 0;
  font-size: 14px;
  padding: 8px 16px;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
  animation: popIn 0.2s ease-out;
}

.notificationCard button:hover {
  color: white !important;
  background-color: #4339f2 !important;
}

.notificationItem {
  cursor: pointer;
  border: 0 !important;
  margin: 6px 0;
}

.notificationItem p {
  color: #858585;
  font-weight: 300;
  font-size: 12px;
}

.notificationItem:hover {
  color: #4339f2;
  background-color: #f1f0ff;
}

.newNotification {
  background-color: #e7f3ff;
}

.notificationTime {
  white-space: nowrap;
}

.notification {
  display: flex;
  align-items: center;
  position: relative;
}

.notification i {
  font-size: 24px;
  color: #000;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: #fff;
  height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 18px;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

/**** NOTIFICATIONS PAGE ****/
.notifications-container {
  background-color: #f8f9fa;
  padding: 20px 0 20px 20px;
  border-radius: 8px;
}

.table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
}

.border-top {
  border-top: 1px solid #cccccc63;
}

.new {
  background-color: green;
  color: white;
}

.unread {
  background-color: red;
  color: white;
}

.archive {
  background-color: blue;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 23px !important;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

/*** NOTIFICATIONS STATUS ***/
.dotNew, .dotArchive, .dotUnread {
  margin-right: -20px;
  margin-left: -30px;
}

.dotNew svg, .dotUnread svg, .dotArchive svg {
  margin: -24px 0;
}

.statusNew, .statusAll, .statusArchieve, .statusUnread {
  padding: 4px 24px;
}

.dotNew {
  color: #34b53a;
}

.dotUnread {
  color: #ff3333;
}

.dotArchive {
  color: #4339f2;
}

.statusBlock span {
  color: #858585;
}
