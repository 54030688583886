.productCatalogue {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-actions {
  font-size: 11px;
  text-align: center;
}

.table {
  font-size: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
}

.table-wrapper {
  display: flex;
  gap: 24px;
}

.table-wrapper td {
  height: 4.5rem;
  vertical-align: middle;
}

.col-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-wrapper th {
  vertical-align: middle;
}

.table-2 td {
  border-width: 1px;
}

.table-2 {
  width: -webkit-fill-available;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
}

.title {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.select {
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* width: 82px; */
  color: #9a9a9a;
  font-size: 11px;
  height: 24px;
  background-color: #fff;
}

.searchInput {
  padding-top: 2px;
  /* align-items: center; */
  /* display: flex; */
  border: none;
}

.searchContainer {
  display: flex;
  background: white;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 11px;
  gap: 6px;
  /* align-items: center; */
  color: #9a9a9a;
}

.searchIcon {
  position: absolute;
  right: 10px;
}

.notification {
  display: flex;
  align-items: center;
  position: relative;
}

.notification i {
  font-size: 24px;
  color: #000;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

.actions-tags {
  padding: 3px 12px;
  border-radius: 16px;
  cursor: pointer;
}

.actions-tags-buy {
  background: #ebf8eb;
  color: #34b53a;
}

.actions-tags-purchased {
  background: #e7e6ff;
  color: #ad00ff;
}

.actions-tags-request {
  background: #ecebfe;
  color: #4339f2;
}

.table-title-actions {
  height: 3.2rem;
  font-size: 12px;
  vertical-align: middle;
}

/** COLUMN STATUS **/
.statusContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.status {
  padding: 3px 10px;
  border-radius: 32px;
  font-size: 11px;
  display: flex;
}

.statusNoQuery {
  background-color: #ffffde;
  color: #bfaa32;
}

.statusPending {
  background-color: #fff7de;
  color: #ff9800;
}

.statusRejected {
  background-color: #ffe2e2;
  color: #af4c4c;
}

.statusRequest {
  background: #ecebfe;
  color: #4339f2;
}

@media (min-width: 576px) {
  .responsive-width {
    max-width: 540px;
  }
  .responsive-width-table {
    max-width: 516px;
  }
}

@media (min-width: 768px) {
  .responsive-width {
    max-width: 720px;
  }
  .responsive-width-table {
    max-width: 696px;
  }
}

@media (min-width: 992px) {
  .responsive-width {
    max-width: 960px;
  }
  .responsive-width-table {
    max-width: 936px;
  }
}

@media (min-width: 1200px) {
  .responsive-width {
    max-width: 1140px;
  }
  .responsive-width-table {
    max-width: 1116px;
  }
}

@media (min-width: 1400px) {
  .responsive-width {
    max-width: 1320px;
  }
  .responsive-width-table {
    max-width: 1296px;
  }
}

@media (min-width: 1600px) {
  .responsive-width {
    max-width: 1460px;
  }
  .responsive-width-table {
    max-width: 1460px;
  }
}

@media (min-width: 1920px) {
  .responsive-width {
    max-width: 1776px;
  }
  .responsive-width-table {
    max-width: 1776px;
  }
}
