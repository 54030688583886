/* Chart.module.css */
.chart-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.chart-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.chart-bar {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
}

.chartWrapper {
  height: 70%;
  float: none;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}
